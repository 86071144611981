import React from "react";
import './Books.css'
import { Container } from "react-bootstrap";
import Image from 'react-bootstrap/Image';
import Row from "react-bootstrap/Row";
import Col from 'react-bootstrap/Col';
import logo from '../assests/images/logo.jpg'
import '../App.css'
import book1 from '../assests/images/Book cover.jpg'
import { MdHelp } from "react-icons/md";



const Books = () => {

    return (
        <>
            <Container>
                <Row>
                    <Col md={12} lg={12} xs={12} sm={12}>
                        <div className="help-section d-flex">
                            <MdHelp className="info" fontSize={22} title="help section"/>
                            <p className="info">If you have inquiries regarding: Speaking engagements, Events, or signings, please contact us here:
                               <a href="#"> contactus@theartofseries.com</a>
                            </p>
                        </div>
                        <Row>   
                            <Col md={12} lg={6} xs={12} sm={12}>
                            <div className="main-img">
                            <Image src={`${book1}`} className="bg-image mt-1" alt="book image" thumbnail />
                        </div>
                                </Col>
                        <Col md={12} lg={6} xs={12} sm={12}>
                        <div className="my-2">
                            <img src={`${logo}`} className="book-img" style={{ width: '280px', mixBlendMode: 'multiply' }} alt="book pic" />
                            <hr />
                            <h2 style={{ fontFamily: 'serif' }}>
                                Standard edition
                            </h2>
                            <div style={{ margin: '15px 0', display: 'flex', textAlign: 'justify', justifyContent: 'center' }}>
                                <span style={{ fontSize: '17px' }}>
                                    Take A journey through the human experience and a view of the human condition, told from a unique perspective. A perspective seen through the lens of the inner-city. These real-life accounts are told from my perception of my surrounding environment and its effects on mental health, which was formed as a child growing up in inner-city America, to the various experiences as an adult while caring for returning citizens from prison, in the exact same city (Detroit).
                                </span>
                            </div>
                            <div className="d-flex  mb-4">
                                <div className="buy-now-btn mx-3">
                                    <a href="https://sowl.co/s/CxtpY" target="_blank" rel="noreferrer">Buy Paperback - $14.99</a>
                                </div>
                                <div className="buy-now-btn">
                                    <a href="https://sowl.co/s/u7hCM" target="_blank" rel="noreferrer">Buy E-book - $9.99</a>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="mt-4">
                            <h2 style={{ fontFamily: 'serif' }}>
                                Entrepreneur edition
                            </h2>
                            <div style={{ margin: '15px 0', display: 'flex', textAlign: 'justify', justifyContent: 'center' }}>
                                <span style={{ fontSize: '17px' }}>
                                    The entrepreneur edition provides several pages of bonus material outlining best practices as well as the mistakes made while owning and operating several group homes. This version of the Art of Care also delves into the human connection between mental health issues experienced by us all, no matter economic status, race, creed or color. A unique first-person account and point of view of the obstacles associated with the struggle to thrive in inner-city America!
                                </span>
                            </div>

                            <div className="d-flex">
                                <div className="buy-now-btn mx-3">
                                    <a href="https://sowl.co/s/simde" target="_blank" rel="noreferrer">Buy Paperback - $19.99</a>
                                </div>
                                <div className="buy-now-btn">
                                    <a href="https://sowl.co/s/bgwyPx" target="_blank" rel="noreferrer">Buy E-book - $14.99</a>
                                </div>
                            </div>
                        </div>
                    </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Books;