import React from "react";
import Container from 'react-bootstrap/Container';
import { PopupButton } from "react-calendly";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from '../assests/images/logo1-removebg-preview.png'
import Books from "./Books";
import './Books.css'

const Header = () => {
    return (
        <>
            <Navbar bg="light" data-bs-theme="light" expand="lg" >
                <Container >
                    <Navbar.Brand><img src={`${logo}`} className="brand-image" alt="logo" /></Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav>
                            <Nav.Link> <PopupButton
                                url="https://calendly.com/opatcareportal/30min"
                                rootElement={document.getElementById("root")}
                                text="Book consultation"
                                styles={{ backgroundColor: 'transparent', cursor: 'pointer', border: 'none', color: '#212529' }}
                            /></Nav.Link>
                            <Nav.Link className="headbar" style={{ color: '#212529' }}>Buy merchandise</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <div className="py-4 main">
                <Books />
            </div>
        </>
    )
}

export default Header