import Header from './components/Header';
import 'bootstrap/dist/css/bootstrap.min.css';

  function App() {
  return (
    <div className="App">
      <div>
        <Header />
      </div>
    </div>
  );
}

export default App;
